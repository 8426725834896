import { Intent, Button } from '@blueprintjs/core';
import styles from './index.module.css';
import { RHFCheckbox, RHFSelect, RHFTextInput } from 'components/RHFInputs';
import { MetricOutputInput } from 'graphql/generated/graphql';
import { get, useFormContext } from 'react-hook-form';
import { metricCalculationMethodItems } from '../../constants';
import { useEffect } from 'react';

type MetricOutputRowProps = {
  output: Partial<MetricOutputInput>;
  index: number;
  channelOptions: { label: string, value: number }[];
}

const MetricOutputRow = ({ output, index, channelOptions } : MetricOutputRowProps) => {
  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();

  const channelId = watch(`outputs.${index}.channel_id`);

  useEffect(() => {
    const channel = channelOptions.find(c => c.value === channelId);
    if (channel) {
      setValue(`outputs.${index}.channel.name`, channel.label);
      setValue(`outputs.${index}.channel.id`, channel.value);
    }
  }, [channelId]);

  const handleRemoveOutput = () => {
    const currentOutputs = getValues('outputs') ?? [];
    currentOutputs.splice(index, 1);
    setValue('outputs', currentOutputs);
  };

  return (
    <div className={styles.inputRow}>
      <RHFCheckbox
        controllerProps={{
          control,
          name: `outputs.${index}.enabled`,
        }}
        checkboxProps={{
          className: styles.inlineCheckbox,
          checked: output.enabled,
        }}
      />
      <RHFTextInput
        controllerProps={{
          control,
          name: `outputs.${index}.channel.name`,
        }}
        inputProps={{
          className: styles.inline,
          value: getValues(`outputs.${index}.channel.name`),
          readOnly: true,
        }}
      />
      <div className={styles.inlineButton}>
        <RHFSelect
          controllerProps={{
            control,
            name: `outputs.${index}.channel_id`,
          }}
          items={channelOptions}
          selectProps={{
            fill: true,
            filterable: true,
          }}
          buttonProps={{
            rightIcon: 'more',
            text: '',
          }}
        />
      </div>
      <div className={styles.inlineSelect}>
        <RHFSelect
          controllerProps={{
            control,
            name: `outputs.${index}.calculation_method`,
          }}
          intent={get(errors, 'position') && Intent.DANGER}
          items={metricCalculationMethodItems}
          fill
        />
      </div>
      <RHFTextInput
        controllerProps={{
          control,
          name: `outputs.${index}.name`,
        }}
        inputProps={{
          className: styles.inline,
          intent: get(errors, `outputs.${index}.name`) && Intent.DANGER,
        }}
      />
      <div className={styles.inlineButton}>
        <Button
          intent={Intent.DANGER}
          type="button"
          icon="cross"
          minimal
          onClick={handleRemoveOutput}
        />
      </div>
    </div>
  );
};

export default MetricOutputRow;
