import { Button, Divider, Intent } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import styles from './index.module.css';
import React, { useEffect, useState } from 'react';
import { SetupSelection } from '../../types';
import AddSetupsModal from '../SelectorModal/setup';
import _ from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { baseName } from '../../config';
import { SetupCompareSlice } from '../../reducers/setupCompare';
import { useSetupBranchesByIdsQuery } from '../../graphql/generated/graphql';
import SetupCheckbox from '../SetupCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { COLORS } from '../../constants';
import AppToaster from 'helpers/toaster';

interface Props {
  showExtended: boolean;
}

export default (props: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const branchIds = [branchId, ...compareIds?.split(',').map(Number) ?? []];
  const [baselineSetup, setBaselineSetup] = useState<SetupSelection>();
  const [selectableSetups, setSelectableSetups] = useState<SetupSelection[]>([]);
  const [selectedSetups, setSelectedSetups] = useState<SetupSelection[]>([]);
  const [compareSetups, setCompareSetups] = useState<SetupSelection[]>([]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    let compareUrl = baseName === '/'
      ? `${window.location.origin}${location.pathname}`
      : `${window.location.origin}${baseName}${location.pathname}`;

    const compareIds = selectedSetups.map(s => s.branch.id);
    if (!_.isEmpty(compareIds)) compareUrl += `?ids=${_.join(compareIds)}`;

    window.history.replaceState(null, '', compareUrl);

    // Update Local Stores with Selected Compare Setups
    const currentSetupCompareState = { [branchId]: [...compareIds || []] };
    dispatch(SetupCompareSlice.actions.setSetupCompareState(currentSetupCompareState));
  }, [selectedSetups]);

  useEffect(() => {
    if (compareSetups.length > 0) {
      setSelectableSetups(compareSetups);
      setSelectedSetups(compareSetups);
    }
  }, [compareSetups]);

  useSetupBranchesByIdsQuery({
    variables: { branchIds },
    onCompleted: data => {
      const branchHeads = data.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));
      const [baselineBranch, ...compareBranches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);
      setBaselineSetup(baselineBranch);
      setCompareSetups(compareBranches);
    },
    fetchPolicy: 'network-only',
  });

  const handleSelectSetup = (setup: SetupSelection) => {
    const newSetups = [...selectedSetups];
    const idIndex = newSetups.findIndex(s => s.branch.id === setup.branch.id);
    if (idIndex > -1) {
      newSetups.splice(idIndex, 1);
    } else {
      newSetups.push(setup);
    }
    setSelectedSetups(newSetups);
  };

  const handleRemoveSetup = (id: number) => {
    const newSelectedSetups = [...selectedSetups];
    const selectedIdIndex = newSelectedSetups.findIndex(s => s.branch.id === id);
    if (selectedIdIndex > -1) {
      newSelectedSetups.splice(selectedIdIndex, 1);
      setSelectedSetups(newSelectedSetups);
    }

    const newSelectableSetups = [...selectableSetups];
    const selectableIdIndex = newSelectableSetups.findIndex(s => s.branch.id === id);
    if (selectableIdIndex > -1) {
      newSelectableSetups.splice(selectableIdIndex, 1);
      setSelectableSetups(newSelectableSetups);
    }
  };
  const handleUpdateSetupColor = (id: number) => {
    AppToaster.show({
      intent: Intent.SUCCESS,
      message: `To be Implemented, Set color for setup ID:${id}`,
    });
  };

  const onModalSuccess = (rows: SetupSelection[]) => {
    setIsDialogOpen(false);

    const newSetups: SetupSelection[] = _.filter(rows, r => r.branch.id !== baselineSetup?.branch.id);
    setSelectableSetups(_.uniqBy([...selectableSetups, ...newSetups], 'branch.id'));
    setSelectedSetups([
      ...selectedSetups,
      ...rows.filter(r => r.branch.id !== baselineSetup?.branch.id && !selectedSetups.find(s => s.branch.id === r.branch.id)),
    ]);
  };

  const highlightedIds: number[] = selectableSetups.map(selection => selection.setup.id);
  highlightedIds.push(branchId); // Add baseline setup id
  const activeIds: number[] = highlightedIds.filter(id => selectedSetups.some(setup => setup.setup.id === id));
  return (
    <div className={styles.setupPaneContainer}>
      <Tooltip2 content="Add Setups(s)">
        <Button
          className={styles.addSetupButton}
          icon="plus"
          onClick={() => setIsDialogOpen(true)}
        />
      </Tooltip2>
      <div className={styles.selectedSetupsRoot}>
        {selectableSetups.length > 0 && (
          <Divider style={props.showExtended ? { width: '90%' } : { width: '21px' }} />
        )}
        {selectableSetups.map((compareSetup: SetupSelection) => {
          const setupColor: string = activeIds.includes(compareSetup.setup.id) ? COLORS[activeIds.indexOf(compareSetup.setup.id) + 1] : '#FFFFFF';
          return (
            <div className={styles.setupSelectRow}>
              <div className={styles.setupSelectRowDetail}>
                <SetupCheckbox
                  checked={selectedSetups.some(s => s.branch.id === compareSetup.branch.id)}
                  color={setupColor}
                  setupId={compareSetup.setup.id}
                  showExtended={props.showExtended}
                  setupName={compareSetup.setup.name}
                  branchName={compareSetup.branch.name}
                  key={compareSetup.branch.id.toString()}
                  tooltip={compareSetup.setup.name || ''}
                  onChange={() => handleSelectSetup(compareSetup)}
                />
              </div>
              <div className={props.showExtended ? styles.setupSelectRowControls : styles.setupSelectRowHide}>
                {activeIds.includes(compareSetup.setup.id) && (
                  <Tooltip2
                    className="btnEditSetupColor"
                    content="Edit Setup Color"
                  >
                    <FontAwesomeIcon icon={faPencil} onClick={() => handleUpdateSetupColor(compareSetup.branch.id)} color={setupColor} />
                  </Tooltip2>
                )}
                <Button
                  icon="cross"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => handleRemoveSetup(compareSetup.branch.id)}
                  small
                />
              </div>
            </div>
          );
        })}
        <AddSetupsModal
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSuccess={onModalSuccess}
          highlightedRows={highlightedIds}
        />
      </div>
    </div>
  );
};
