import { useRef, useState } from 'react';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';

import PartForm from 'components/PartForm';
import { PartProperty, ClonePartInput, usePartByIdQuery, useCreatePartMutation } from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { defaultPart, loadPartPropertyValues, transformPartPropertyValues } from 'helpers/part';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { omit, find, get } from 'lodash';

interface PartFormMethods {
  getData: () => any | null; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default () => {
  const params = useParams();
  const partId = Number(params.partId);
  const formRef = useRef<PartFormMethods>(null);
  const [partDescription, setPartDescription] = useState('');

  useDocumentTitle(
    partDescription  ? `Apex Setup - ${partDescription}` : 'Apex Setup'
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const form = useForm<ClonePartInput>({ defaultValues: { ...defaultPart } });

  const { loading: partLoading } = usePartByIdQuery({
    variables: { id: partId },
    fetchPolicy: 'cache-and-network',
    skip: !partId,
    onCompleted: data => {
      if (data.part) {
        form.reset({
          ...omit(data.part, 'config'),
          part_config_id: data.part.config.id,
          properties: loadPartPropertyValues(data.part.properties as PartProperty[]),
        });
        setPartDescription(data.part.description);
      } else {
        setPartDescription('');
      }
    },
  });
  const [createPart] = useCreatePartMutation();

  const onSubmit = (input: ClonePartInput) => {
    if (!formRef.current) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Form reference is not available',
      });
      return;
    }

    const data = formRef.current.getData(); // Get data from the child form

    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    delete input.id; // remove ID of part we're cloning
    createPart({
      variables: {
        input: {
          ...input,
          data,
          exp_date: input.exp_date ? input.exp_date : null,
          properties: transformPartPropertyValues(input.properties as PartProperty[]),
          organization_id: get(findOrg, 'id', ''),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part successfully cloned',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error cloning part: ${e.message}`,
        });
      },
    });
  };

  return (
    <div className={styles.partFormContainer}>
      {!partLoading && (
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className={styles.headerBar}>
              <H3>Clone Part</H3>
              <Button
                className={styles.saveButton}
                icon="floppy-disk"
                intent={Intent.PRIMARY}
                large
                text="Save"
                type="submit"
              />
            </div>
            <PartForm ref={formRef} />
          </form>
        </FormProvider>
      )}
    </div>
  );
};
