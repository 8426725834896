import styles from './index.module.css';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AppToaster from 'helpers/toaster';
import { EditableText, Intent } from '@blueprintjs/core';
import { SweepPayload } from './types';
import { flatten } from 'lodash';

type SweepPayloadTableProps = {
  sweepPayload?: SweepPayload[];
  setSweepPayload: (input: SweepPayload[]) => void;
  deltaParameters?: string[],
}

type Parameter = {
  path: string,
  values: string[] | number[],
};

const SweepPayloadTable = (props : SweepPayloadTableProps) => {
  const { sweepPayload, setSweepPayload, deltaParameters } = props;
  const [parameters, setParameters] = useState<Parameter[][]>([]);
  const [tableLength, setTableLength] = useState<number>(0);

  useEffect(() => {
    if (!sweepPayload) return;
    try {
      setTableLength(sweepPayload.reduce((acc, sp) => acc + sp.sweepDataLength, 0));
      const paramList = sweepPayload.map(sp => {
        const parsedParameterPaths = Object.getOwnPropertyNames(sp.sweepData);
        const parsedParameters = parsedParameterPaths.map((p: string) => {
          return {
            path: p,
            values: sp.sweepData[p],
          };
        });

        return parsedParameters;
      });
      setParameters(paramList);
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Error importing payload',
      });
    }
  }, [sweepPayload]);

  const onCellValueChange = useCallback((parameter: Parameter, value: string, index: number) => {
    if (sweepPayload) {
      const newSweepPayload = sweepPayload;
      parameter.values[index] = value;
      const newParams = parameters.map((sweepGroup, groupIndex) => {
        return sweepGroup.map(p => {
          if (p.path === parameter.path) {
            newSweepPayload[groupIndex].sweepData[parameter.path] = parameter.values;
            return parameter;
          }
          newSweepPayload[groupIndex].sweepData[p.path] = p.values;
          return p;
        });
      });
      setParameters(newParams);
      setSweepPayload(newSweepPayload);
    }
  }, [parameters]);

  const renderSweepDataTable = (
    tableLength: number,
    parameters: Parameter[][],
    payload: SweepPayload[],
  ): ReactNode => {
    let currentGroupIndex = 0;
    let groupDataIndex = 0;
    return [...Array(tableLength)].map((item, rowIndex) => {
      const currentGroup = payload[currentGroupIndex];
      const tableRow = (
        <tr className={styles.textCenterRow}>
          <td>{rowIndex + 1}</td>
          {flatten(parameters).map(parameter => {
            if (currentGroup?.sweepData[parameter.path]) {
              return (
                <td>
                  {deltaParameters?.find(d => parameter.path === d) && (<>Δ</>)}
                  <EditableText
                    value={parameter.values[groupDataIndex]?.toString()}
                    onChange={value => {
                      onCellValueChange(parameter, value, groupDataIndex);
                    }}
                    selectAllOnFocus
                  />
                </td>
              );
            }
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            return (<td />);
          })}
        </tr>
      );

      if ((groupDataIndex + 1 >= currentGroup?.sweepDataLength) && (currentGroupIndex < payload.length)) {
        currentGroupIndex++;
        groupDataIndex = 0;
      } else {
        groupDataIndex++;
      }
      return tableRow;
    });
  };

  return (
    <table className={styles.payloadTable}>
      {parameters.length > 0 && sweepPayload && (
        <>
          <tr className={styles.textCenterColumnHeader}>
            <th>Run</th>
            {flatten(parameters).map((parameter) => (
              <th>{parameter.path}</th>
            ))}
          </tr>
          {renderSweepDataTable(tableLength, parameters, sweepPayload)}
        </>
      )}
    </table>
  );
};

export default SweepPayloadTable;
