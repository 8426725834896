import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { InputGroup, Checkbox } from '@blueprintjs/core';

import Select from 'components/Select';
import sampleTireData from 'fixtures/sample-tire-data.json';
import { useSetupByBranchIdQuery } from 'graphql/generated/graphql';
import { GQLSetup } from 'types';
import CornerInputs, { CornerInputsSuffixes } from './CornerInputs';
import TitleBar from '../TitleBar';
import TireCharts from './TireCharts';
import { hasPermission } from '../../../helpers/permissions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { PermissionName } from '../../../constants';

export default () => {
  const params = useParams();
  const branchId = Number(params.branchId);

  const { width: resizeWidth, height: resizeHeight, ref: resizeRef } = useResizeDetector();
  const titleBarRef = useRef<HTMLDivElement>(null);
  const [setup, setSetup] = useState<GQLSetup>();
  const [plotType, setPlotType] = useState('Lateral Force (N) vs Slip Angle');
  const [fieldsContainerHeight, setFieldsContainerHeight] = useState(100);

  useDocumentTitle(
    setup && setup.name ? `Apex Setup - ${setup.name}` : 'Apex Setup'
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  useSetupByBranchIdQuery({
    variables: { branchId },
    onCompleted: data => setSetup(data.setup as GQLSetup),
  });

  const plotTypes = [{
    label: 'Lateral Force (N) vs Slip Angle',
    value: 'Lateral Force (N) vs Slip Angle',
  }];

  useEffect(() => {
    if (titleBarRef.current) {
      const { bottom } = titleBarRef.current.getBoundingClientRect();
      setFieldsContainerHeight(window.innerHeight - bottom - 30 - 8); // 30 for the apex toolbar
    }
  }, [resizeWidth, resizeHeight]);

  useEffect(() => {
    // TODO: use vals
    // eslint-disable-next-line
  }, [plotType]);

  const handleCornerInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
    // TODO: use vals
    // eslint-disable-next-line
    const { id, value } = e.target;
    switch (id) {
      case `fz${CornerInputsSuffixes.lf}`:
        break;
      case `fz${CornerInputsSuffixes.rf}`:
        break;
      case `fz${CornerInputsSuffixes.lr}`:
        break;
      case `fz${CornerInputsSuffixes.rr}`:
        break;
      default:
    }
  };

  // TODO: Show loading
  if (!setup) return null;
  if (!hasPermission(PermissionName.TIRES_VIEW_ACCESS, permissions)) return null;

  return (
    <div ref={resizeRef} className={styles.mainContainer}>
      <TitleBar
        setup={setup}
        forwardedRef={titleBarRef}
      >
        <div className={styles.controlsContainer} />
      </TitleBar>
      <div className={styles.bodyContainer} style={{ height: `${fieldsContainerHeight}px` }}>
        <TireCharts data={sampleTireData.output} />
        <div className={styles.fieldsContainer}>
          <div className={styles.fieldRow}>
            <div>
              Plot
            </div>
            <div className={styles.fieldValue}>
              <Select
                onChange={(item) => setPlotType(item.value)}
                items={plotTypes}
                selectProps={{ filterable: false }}
                fill
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div>
              Tire States
            </div>
            <div className={styles.fieldValue}>
              <Select
                onChange={(item) => setPlotType(item.value)}
                items={plotTypes}
                selectProps={{ filterable: false }}
                fill
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div>
              Segment
            </div>
            <div className={styles.fieldValue}>
              <Select
                onChange={(item) => setPlotType(item.value)}
                items={plotTypes}
                selectProps={{ filterable: false }}
                fill
              />
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="FZ (lbs)">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Camber (degs)">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Slip Angle (degs)">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Slip Ratio (%)">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div>
              Sim States
            </div>
            <div className={styles.fieldValue}>
              <Select
                onChange={(item) => setPlotType(item.value)}
                items={plotTypes}
                selectProps={{ filterable: false }}
                fill
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div>
              States
            </div>
            <div className={styles.fieldValue}>
              <Select
                onChange={(item) => setPlotType(item.value)}
                items={plotTypes}
                selectProps={{ filterable: false }}
                fill
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <Checkbox checked label="Custom State" />
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Pressures">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Temperatures">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Lateral Peak Mu">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Longitudinal Peak Mu">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Cornering Stiffness">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Slip Stiffness">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Lat. Fall Off After Peak">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Long. Fall Off After Peak">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Squareness Combined">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
          <div className={styles.cornerInputsRow}>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Lat. Fall Off After Peak">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
            <div className={styles.cornerInputsCol}>
              <CornerInputs label="Long. Fall Off After Peak">
                <InputGroup onChange={handleCornerInputsChange} />
              </CornerInputs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
