import React, { CSSProperties, ReactNode, useEffect, useMemo, useState, FocusEvent } from 'react';
import { Alignment, Button, Checkbox, InputGroup, Overlay, Spinner, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { get, set, groupBy, isNil, snakeCase, startCase, unset, cloneDeep, includes } from 'lodash';
import { useRouteLoaderData } from 'react-router-dom';
import {
  SelectItem,
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateFieldItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';
import Accordion from 'components/Accordion';
import Select from 'components/Select';
import {
  Part,
  SetupField,
  SetupFieldPosition,
  SetupFieldType,
  useSUITFormPartsLazyQuery,
} from 'graphql/generated/graphql';
import { suitPathToKey } from 'helpers/suit';
import PartSelector from './PartSelector';
import IconTooltip from 'components/IconTooltip';
import { getOrgIds, hasPermission } from '../../helpers/permissions';
import { OrganizationsList, PermissionName } from '../../constants';
import styles from './index.module.css';

export type SetupFieldHandler = (field: SetupField, value: boolean | number | string | Part | unknown) => void;

interface SetupViewProps {
  template: SetupUITemplate;
  setupFields: SetupField[];
  initialSetup?: object;
  onChange?: SetupFieldHandler;
  team?: string;
  organization?: string;
}

type ChildObjectType = Record<string, unknown>;

const SUITFormComponent = (props: SetupViewProps) => {
  const { template, setupFields, initialSetup, onChange } = props;

  const [parts, setParts] = useState<Part[]>([]);
  const [getParts, { loading: partsLoading }] = useSUITFormPartsLazyQuery({
    onCompleted: data => setParts(data.parts.rows as Part[]),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions, organizations } } = useRouteLoaderData('root') as any;

  // If a team setup and user is not a TRD member, get team specific permissions
  if (props.team && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
    const findTeam = teams.find((t: { id: string; }) => t.id === props.team);
    permissions = findTeam ? findTeam.permissions.map((p: { name: string; }) => p.name) : [];
  }

  const partsByConfig = useMemo(() => {
    if (!parts) return {};
    return groupBy(parts, p => p.config.name);
  }, [parts]);

  useEffect(() => {
    const partConfigIds: number[] = [];
    const handleSetupField = (field: SetupUITemplateFieldItem) => {
      const setupField = setupFields.find(sf => sf.name === field.setup_field);
      if (setupField?.part_config) partConfigIds.push(setupField.part_config.id);
    };

    const processTemplateItem = (item: SetupUITemplateItem) => {
      if (item.type === SetupUITemplateItemType.CONTAINER) {
        item.items.forEach(processTemplateItem);
      } else {
        handleSetupField(item);
      }
    };
    template.items.forEach(processTemplateItem);

    getParts({
      variables: {
        input: {
          filters: {
            part_config: partConfigIds,
          },
        },
      },
    });
  }, [template]);

  const getFieldPath = (path: string, position?: SetupFieldPosition) => {
    if (!position) return path;
    if (!position.path_part) return `${path}_${snakeCase(position.label)}`;

    const pathParts = path.split('.');
    pathParts.splice(-1, 1, position.path_part);
    return pathParts.join('.');
  };

  const handleOnChange = (field: SetupField, value: unknown, collectionItemKey?: string, parentSetupField?: SetupField) => {
    let updatedSetupField = field;
    let updatedValue = value;
    if (onChange) {
      if (collectionItemKey && parentSetupField && initialSetup) { // If this is a dynamic collection type, we need to update the parent field/value
        const updatedCollectionObj = cloneDeep(get(initialSetup, parentSetupField.path, {}));
        set(updatedCollectionObj, `${collectionItemKey}.${field.path}`, value);
        updatedSetupField = parentSetupField;
        updatedValue = updatedCollectionObj;
      }
      onChange(updatedSetupField, updatedValue);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();

  const renderSetupField = (
    field: SetupField,
    readOnly: boolean,
    position?: SetupFieldPosition,
    initialSetup?: object,
    onChange?: SetupFieldHandler,
    isChild?: boolean,
    childObj?: ChildObjectType,
    collectionItemKey?: string,
    parentSetupField?: SetupField
  ): ReactNode => {
    const dataObj = (isChild && childObj) ? childObj : initialSetup; // If Collection Child, data path relative to childObj parent
    let initialVal = get(dataObj, field.path);
    if (isNil(initialVal)) initialVal = '';

    const renderControlWithLabel = (control: ReactNode, label: string | undefined, tooltip: string | '') => {
      if (isChild && label) {
        return (
          <div key={`${field.path}_wrapper`} className={styles.childWrapper}>
            <div className={styles.childLabel}>{label}</div>
            <div className={styles.childToolTip}>
              <IconTooltip
                className={styles.tooltipIcon}
                content={tooltip}
              />
            </div>
            {control}
          </div>
        );
      }
      return control;
    };

    const handleAddCollectionItemClick = () => {
      if (field.setup_field_array && Array.isArray(field.setup_field_array)) {
        const setupObjsData = cloneDeep(get(initialSetup, field.path, {}));
        if (initialSetup && Object.keys(setupObjsData).length === 0) {
          const collectionObj = {};
          field.setup_field_array.forEach((childField) => {
            set(collectionObj, `${field.name}_0.${childField.path}`, '');
          });
          onChange?.(field, collectionObj);
        } else if (initialSetup) {
          let index = 0;
          let found = false;
          let newKey = '';
          while (!found) {
            const existingKey = `${field.name}_${index}`;
            if (!Object.keys(setupObjsData).includes(existingKey)) {
              newKey = existingKey;
              found = true;
            }
            index++;
          }
          field.setup_field_array.forEach((childField) => {
            set(setupObjsData, `${newKey}.${childField.path}`, '');
          });
          onChange?.(field, setupObjsData);
        }
      }
    };

    const handleRemoveCollectionItemClick = (event: React.MouseEvent<HTMLElement>, itemKey: string) => {
      const setupObjsData = cloneDeep(get(initialSetup, field.path, {}));
      unset(setupObjsData, itemKey);
      onChange?.(field, setupObjsData);
    };

    const renderCollectionChildren = (parentSetupField: SetupField) => {
      const setupObjsData = get(initialSetup, field.path, {});
      const childSetupFields: SetupField[] = field.setup_field_array || [];

      // Create a mapping between child_ids and child fields
      const childFieldMap = new Map();
      childSetupFields.forEach((field) => {
        childFieldMap.set(field.id, field);
      });

      return (
        <div>
          {Object.entries(setupObjsData).map(([key, setupObjData], index) => (
            <div key={`sf_parent_${key}`} className={styles.childContainer}>
              {/* Render child fields in the order of child_ids */}
              {Array.from(childFieldMap.values()).map((childField) => {
                return (
                  <div key={`sf_child_${childField.id}`}>
                    {renderSetupField(childField, readOnly, undefined, initialSetup, onChange, true, setupObjData as Record<string, unknown>, key, parentSetupField)}
                  </div>
                );
              })}

              {Object.keys(setupObjsData).length > 0 ? (
                <div key={`sf_parent_controls_${key}`} className={styles.childControls}>
                  <Button
                    intent={Intent.DANGER}
                    key={`remove_setup_field_collection_item_${index}`}
                    icon="cross"
                    onClick={(event) => handleRemoveCollectionItemClick(event, key)}
                  />
                  {(Object.keys(setupObjsData).length === (index + 1))
                    && (
                      <Button
                        intent={Intent.SUCCESS}
                        key={`add_setup_field_collection_item_${index}`}
                        icon="plus"
                        onClick={handleAddCollectionItemClick}
                      />
                    )}
                </div>
              ) : (
                <span style={{ minWidth: '30px' }} />
              )}
            </div>
          ))}

          {Object.keys(setupObjsData).length === 0 && initialSetup && (
            <div>
              <Button
                intent={Intent.SUCCESS}
                key="add_setup_field_collection_item_0"
                icon="plus"
                onClick={handleAddCollectionItemClick}
              />
            </div>
          )}

          {Object.keys(setupObjsData).length === 0 && !initialSetup && (
            <div key="sf_parent_" className={styles.childContainer}>
              {Array.from(childFieldMap.values()).map((childField) => {
                return (
                  <div key={`sf_child_${childField.id}`}>
                    {renderSetupField(childField, readOnly, undefined, initialSetup, onChange, true)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    };

    if (!field) return 'Missing SetupField';

    switch (field.type) {
      case SetupFieldType.COLLECTION: {
        if (!field.setup_field_array || !Array.isArray(field.setup_field_array)) {
          return 'MISSING SetupFIELD Collection Items';
        }
        return renderCollectionChildren({ ...field });
      }
      case SetupFieldType.PART: {
        const partConfigName = field.part_config?.name || '';
        const configParts = partsByConfig[partConfigName] ?? [];
        const selectedPart = configParts.find(p => p.id === initialVal);
        return renderControlWithLabel(
          <PartSelector
            initialPart={selectedPart}
            key={`sf_part_${field.path}`}
            onChange={(partId) => handleOnChange(field, partId, collectionItemKey, parentSetupField)}
            parts={configParts}
            position={position}
            disabled={readOnly}
            buttonStyles={readOnly ? styles.customDisabled : undefined}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => ({ label: o, value: o }));
          const initialOption = options.find(o => o.value === initialVal);
          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          const buttonStyles = readOnly ? classNames(styles.selectButton, styles.customDisabled) : styles.selectButton;
          return renderControlWithLabel(
            <Select
              value={initialOption}
              buttonProps={{ className: buttonStyles, fill: true }}
              fill
              items={options}
              key={`sf_string_${field.path}`}
              noSelectionText={noSelectionText}
              onChange={(item) => handleOnChange(field, item.value, collectionItemKey, parentSetupField)}
              disabled={readOnly}
            />,
            field.label,
            field.tooltip || ''
          );
        }
        return renderControlWithLabel(
          <InputGroup
            fill
            key={`sf_string_${field.path}`}
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            value={initialVal}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={readOnly ? styles.customDisabled : undefined}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.INT: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => ({ label: o, value: o }));
          const initialOption = options.find(o => o.value === initialVal);
          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          const buttonStyles = readOnly ? classNames(styles.selectButton, styles.customDisabled) : styles.selectButton;
          return renderControlWithLabel(
            <Select
              value={initialOption}
              buttonProps={{ className: buttonStyles, fill: true }}
              fill
              items={options}
              key={`sf_int_${field.path}`}
              noSelectionText={noSelectionText}
              onChange={(item) => handleOnChange(field, item.value, collectionItemKey, parentSetupField)}
              disabled={readOnly}
            />,
            field.label,
            field.tooltip || ''
          );
        }
        return renderControlWithLabel(
          <InputGroup
            value={initialVal}
            key={`sf_int_${field.path}`}
            fill
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={readOnly ? styles.customDisabled : undefined}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.FLOAT: {
        return renderControlWithLabel(
          <InputGroup
            value={initialVal}
            key={`sf_float_${field.path}`}
            fill
            placeholder={position?.label}
            onChange={(e) => handleOnChange(field, e.target.value, collectionItemKey, parentSetupField)}
            onFocus={handleFocus}
            disabled={readOnly}
            inputClassName={readOnly ? styles.customDisabled : undefined}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      case SetupFieldType.BOOLEAN: {
        return renderControlWithLabel(
          <Checkbox
            alignIndicator={Alignment.RIGHT}
            className={styles.booleanInput}
            checked={initialVal}
            key={`sf_boolean_${field.path}`}
            label={position && position.label}
            onChange={() => handleOnChange(field, !initialVal, collectionItemKey, parentSetupField)}
            disabled={readOnly}
          />,
          field.label,
          field.tooltip || ''
        );
      }
      default:
        return null;
    }
  };

  const renderTemplateFieldItem = (
    item: SetupUITemplateFieldItem,
    setupFields: SetupField[],
    readOnly: boolean,
    initialSetup?: object,
    onChange?: SetupFieldHandler,
  ) => {
    const field = setupFields.find(f => f.name === item.setup_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldPath(field.path, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderSetupField(expandedField, readOnly, pos, initialSetup, onChange);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderSetupField(field, readOnly, undefined, initialSetup, onChange)];
    }

    return (
      <div key={field.path} className={styles.inputRow}>
        <div>
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div className={classNames(styles.inputs, inputClass)} style={inputStyles}>{inputs}</div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: SetupUITemplateItem[],
    setupFields: SetupField[],
    initialSetup?: object,
    onChange?: SetupFieldHandler
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      // Filter what's viewable by permissions
      if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
        if (item.name.toLowerCase() !== 'car') {
          if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
        }
      }
      const id = suitPathToKey(path as SetupUITemplateContainerItem[]);
      return (
        <Accordion
          className={styles.itemContainer}
          id={id}
          initialOpen
          key={item.name}
          title={item.label ?? startCase(item.name)}
          buttonProps={{
            className: styles.accordionHeader,
          }}
        >
          <div className={styles.sectionContainer}>
            {item.items.map(innerItem => renderTemplateItem([...path, innerItem], setupFields, initialSetup, onChange))}
          </div>
        </Accordion>
      );
    }
    // Check permission to see if field should be read only
    const parentContainer = path[path.length - 2] as SetupUITemplateContainerItem;

    let readOnly = false;

    // Set read only permissions
    if (hasPermission(PermissionName.SETUP_READ, permissions)) {
      readOnly = true;
    } else {
      readOnly = hasPermission(`setup_${parentContainer.name.toLowerCase()}_read`, permissions);
    }

    // Set to Read Only if the setup is owend by TRD and the user is not in the TRD org
    if (props.organization === OrganizationsList.TRD
      && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
      readOnly = true;
    }

    return renderTemplateFieldItem(item, setupFields, readOnly, initialSetup, onChange);
  };

  return (
    <div className={styles.containerRelative}>
      {template?.items.map(item => renderTemplateItem([item], setupFields, initialSetup, onChange))}
      <div className={styles.containerAbsolute}>
        <Overlay isOpen={partsLoading} className="bp3-overlay-scroll-container">
          <div className={styles.loadingSpinner}>
            <Spinner size={50} />
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default SUITFormComponent;
